import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import appConfig from '../../utils/appConfig';
import { Box, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';

// יצירת סגנונות מותאמים אישית באמצעות makeStyles
const useStyles = makeStyles({
  container: {
    maxWidth: 400,
    margin: '0 auto',
  },
  typography: {
    marginBottom: 16,
    textAlign: 'center',
    padding: 16,
    backgroundColor: '#f0f4f8',
    borderRadius: 8,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd',
  },
  iframeContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  boldText: {
    fontWeight: 'bold',
    color: '#333',
  },
  iframe: {
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
});

// interface PaymentResponse {
//   success: boolean;
//   code: string;
//   userId: string;
//   sum: number;
//   email: string;
//   confirmationCode: string;
// }  

// // פונקציה שמטפלת בתגובת התשלום מטרנזילה
// function handlePaymentResponse(event: MessageEvent) {
//   if (event.origin !== 'https://direct.tranzila.com') {
//     // התעלם מהודעות שמגיעות ממקורות לא מוכרים
//     return;
//   }

//   console.log('Received message from Tranzila:', event.data);

//   try {
//     const response: PaymentResponse = JSON.parse(event.data);
//     console.log('Parsed payment response:', response);

//     // שליחת התוצאה לשרת באמצעות POST
//     sendPaymentResult(response);

//   } catch (error) {
//     console.error('Error parsing payment response:', error);
//   }
// }

// // דוגמה לפונקציה מטרנזילה ששולחת בקשת POST
// function sendPaymentResult(response: PaymentResponse) {
//   fetch('https://afternoon.billagan.co.il/api/tranzilaCallback', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(response),
//   })
//     .then(res => res.json())
//     .then(data => console.log('Success:', data))
//     .catch((error) => console.error('Error:', error));
// }

const CreditcardHok: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const formData = location.state;
  console.log('Form Data in CreditcardHok:', formData);

  // const calculateMonthsToJune = () => {
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth(); // מחזיר את החודש הנוכחי כמספר (0-11)
  //   const currentYear = currentDate.getFullYear();

  //   const juneMonth = 5; // 5 מייצג את יוני (0-11)
  //   const juneYear = currentMonth <= juneMonth ? currentYear : currentYear + 1;

  //   let monthsDifference = (juneYear - currentYear) * 12 + (juneMonth - currentMonth) + 1;
  //   monthsDifference = monthsDifference > 10 ? 10 : monthsDifference;

  //   return monthsDifference;
  // };

  

  // const mounts = calculateMonthsToJune();

  interface Player {
    playerFirstName: string;
    // שדות נוספים שנמצאים באובייקט player
  }
  
  const parentName = formData.fatherName? formData.fatherName: formData.motherName;
  const numOfPlayers= formData.players.length;
  const playerNames = formData.players.map((player: Player) => player.playerFirstName).join(', ');
  console.log(playerNames); // מדפיס את כל שמות השחקנים לסטרינג אחד

  const recur_payments = appConfig.monthsToPay-1;
  const sum = (formData.players.reduce((total: number, player: { playerPayment: number }) => total + player.playerPayment, 0))/(recur_payments+1);
  const recur_sum = sum;
  const supplier = appConfig.tranzilaCode;
  const currency = 1;
  const email = formData.email;
  const lang = "il";
  const recur_transaction = "4_approved";
  const contact = `${parentName} ${formData.lastName}`;
  const ID_child = formData.studentId;
  const recur_start_date = "2025-03-10";
  const user_defined_3 = formData.email;
  const user_defined_7 = ` תשלום עבור אליצוד לוד - ${parentName} ${formData.lastName} עבור ${numOfPlayers} שחקנים: ${playerNames} `;
  const user_defined_8 = formData.studentId;
  const pdesc = ` תשלום עבור אליצוד לוד - ${parentName} ${formData.lastName} עבור ${numOfPlayers} שחקנים: ${playerNames} `;
  const type = "אליצור לוד";
  const hidesum = 1;
  const thankYouUrl = appConfig.apiUrls.baseUrl?.replace("/api", "") + "/thankYouPage";
  console.log(thankYouUrl);
  console.log(pdesc);

  const iframeUrl = `https://direct.tranzila.com/${supplier}/iframenew.php?&currency=${currency}&recur_transaction=${recur_transaction}&recur_start_date=${recur_start_date}&lang=${lang}
                     &user_defined_3=${user_defined_3}&user_defined_7=${user_defined_7}&user_defined_8=${user_defined_8}&email=${email}&pdesc=${pdesc}&cred_type=1
                     &recur_sum=${recur_sum}&recur_payments=${recur_payments}&sum=${sum}
                     &contact=${contact}&ID_child=${ID_child}&type=${type}&success_url_address=${thankYouUrl}&fail_url_address=${thankYouUrl}&nologo=1`;

                     useEffect(() => {
                      const storedFormData = localStorage.getItem('formData'); // החלפה ל-localStorage
                        if (storedFormData) {
                          const parsedData = JSON.parse(storedFormData);
                          axios.post(appConfig.tempUrl, parsedData)
                        .then(response => {
                          console.log('formData to Hagay:', parsedData);
                          console.log('Data sent successfully:', response.data);
                        })
                        .catch(error => {
                          console.error('Error sending data:', error.response ? error.response.data : error.message);
                        });
                        }
                      
                    }, [formData]); // תלות ב-formData                    


  return (
    <Box 
    className={classes.container} 
    style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh', // גובה של 100% מהחלון
      textAlign: 'center' ,// מרכז את הטקסט אופקית בתוך הקופסה
    }}
  >
    <div>
      <Typography variant="h6" className={classes.typography}>
        <span className={classes.boldText}>שם ההורה:</span> {parentName} {formData.lastName}
        <br />
        <span className={classes.boldText}>סכום לתשלום:</span> ₪{sum*(recur_payments+1)}
      </Typography>
      <Box 
        className={classes.iframeContainer} 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          marginTop: '20px' // מרווח בין הטקסט ל-iframe
        }}
      >
         <Box 
  style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    padding: '10px', // פדינג סביב ה-iframe
    backgroundColor: '#fff', // צבע הרקע הלבן מאחורי הפדינג
    border: '2px solid #ddd', // מסגרת מסביב ל-iframe
    borderRadius: '8px', // פינות מעוגלות למסגרת
  }}
>
  <iframe
    src={iframeUrl}
    width="370"
    height="455"
    frameBorder="0"
    scrolling="no"
    title="Tranzila Payment"
    style={{
      display: 'block', // להבטיח שה-iframe יוצג כבלוק (מלא רוחב)
      backgroundColor: '#fff', // צבע רקע פנימי של ה-iframe (אם זה משפיע)
    }}
  ></iframe>
</Box>

      </Box>
    </div>
  </Box>
  
  );
};

export default CreditcardHok;
