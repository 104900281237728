import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import appConfig from '../../utils/appConfig';
import { Typography } from '@material-ui/core';
import PlayerDetailsForm from './PlayerDetailsForm';
import { useNavigate } from 'react-router-dom';

//************************************************************************************************
//  אינטרפייס שאותו נשלח בטופס ומיד בהתחלת הקומפ' יש אתחול
//************************************************************************************************

interface Player {
  playerFirstName: string; //שם פרטי שחקן
  playerPhone: string; //מספר טלפון
  idNumber: string;// מספר תעודת זהות
  birthDate: string;// תאריך לידה
  clothingSize: string;// מידת ביגוד
  school: string;// בית ספר
  grade: string;// כיתה
  selection:string;// חוג או קבוצה
  eddEquipment: boolean;
  monthsToPay:number;//  אם מדובר בחוג :הוספת ציוד
  club:string;// אם מדובר בקבוצה- בנים/בנות אם מדובר בחוג- אז זה מיקום החוג
  category:string;//אם מדובר בקבוצה- מאמן
  parentDeclaration: boolean;// הצהרת הורים תנאי רישום
  healthType: string;// קופת חולים 
  playerPayment: number;
}

interface FormData {
  lastName: string; // שם משפחה
  fatherName: string; // שם האב
  fatherPhone: string; // טלפון האב
  motherName: string; // שם האם
  motherPhone: string; // טלפון האם
  email: string; // אימייל
  address: { // כתובת
    street: string;
    number: string;
    city: string;
  };
  players: Player[]; // מערך שחקנים
  conditions: boolean; // תנאים נוספים
}

const numOfPayments= appConfig.monthsToPay;
console.log(numOfPayments)

const Dashboard: React.FC = () => {
  const initialFormData: FormData = {
    lastName: '',
    fatherName: '',
    fatherPhone: '',
    motherName: '',
    motherPhone: '',
    email: '',
    address: {
      street: '',
      number: '',
      city: ''
    },
    players: [{
      playerFirstName: '',
      playerPhone: '',
      idNumber: '',
      birthDate: '',
      clothingSize: '',
      school: '',
      grade: '',
      selection: '',
      eddEquipment: false,
      monthsToPay:numOfPayments,
      club: '',
      category: '',
      parentDeclaration: false,
      healthType: '',
      playerPayment: 0,
     }],
    conditions: false,
  };

  // const fillFormData = (): FormData => {
  //   return {
  //     lastName: 'בדיקה',
  //     fatherName: 'בדיקה',
  //     fatherPhone: '0509876543',
  //     motherName: 'בדיקה',
  //     motherPhone: '0509876543',
  //     email: 'example@example.com',
  //     address: {
  //       street: 'העצמאות',
  //       number: '10',
  //       city: 'תל אביב'
  //     },
  //     players: [{
  //       playerFirstName: 'בדיקה',
  //       playerPhone: '0501112222',
  //       idNumber: '',
  //       birthDate: '2005-01-01',
  //       clothingSize: '12',
  //       school: 'נעם נהורא',
  //       grade:"ד'",
  //       selection: '',
  //       eddEquipment: false,
  //       monthsToPay: 10,
  //       club: '',
  //       category: '',
  //       parentDeclaration: true,
  //       healthType: 'כללית',
  //       playerPayment: 0,
  //     },
  //     {
  //       playerFirstName: 'דוד',
  //       playerPhone: '0502345678',
  //       idNumber: '',
  //       birthDate: '2005-01-01',
  //       clothingSize: '12',
  //       school: 'נעם נהורא',
  //       grade:"ד'",
  //       selection: '',
  //       eddEquipment: false,
  //       monthsToPay: 10,
  //       club: '',
  //       category: '',
  //       parentDeclaration: true,
  //       healthType: 'כללית',
  //       playerPayment: 0,
  //     }
  //   ],
  //     conditions: true,
  //   };
  // };
  // const initialFormData: FormData = fillFormData();

  
  
  

  const [playerId, setPlayerId] = useState<string>('');
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [playerIndex , setPlayerIndex] = useState<number>(0);

  const [showLoginScreen, setShowLoginScreen] = useState(true);
  const [showPlayerForm, setShowPlayerForm] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [playerIdDialogOpen, setPlayerIdDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  
  const [duplicateIdError, setDuplicateIdError] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [checked, setChecked] = useState(false);
  const [totalPlayerPayment , setTotalPlayerPayment] = useState<number>(0);
  const [totalPayment , setTotalPayment] = useState<number>(0);
  const playerSectionRef = useRef<HTMLDivElement>(null); // אפשרות עצמו יחזור ל
  const [addPlayer, setAddPlayer] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  
  const navigate = useNavigate();

  const buttonRef = useRef(null);

//************************************************************************************************
// פונציה שבודקת אם התז קיימת במאגר
//************************************************************************************************

const handlePlayerIdSubmit = async (e: React.FormEvent) => {// המשתמש לחץ המשך כדי לבדוק תז במערכת
  e.preventDefault();

  const year = appConfig.year;
  const data = { idNumber: playerId, year: year };

  // בדיקה אם ה-idNumber כבר קיים במערך players
  const isDuplicate = formData.players.some(player => player.idNumber === playerId);

  if (isDuplicate) {
    setDuplicateIdError(true); // הצגת הודעת שגיאה או כל פעולה מתאימה
    return; // עצירת המשך הפונקציה אם ה-id כבר קיים
  }

  try {
    const url = appConfig.apiUrls.checkIdExistUrl();
    const response = await axios.post(url, data);

    if (response.data === "FALSE") {
      if (addPlayer) {// אנחנו לא בשחקן הראשון
        setPlayerIndex(playerIndex + 1);
        addEmptyPlayer();
        setTotalPlayerPayment(0);
      }

      setShowPlayerForm(true);
      setFormData(prevFormData => ({// הכנסת מספר תז שהמשתמש מילא בכניסה לתוך הטופס
        ...prevFormData,
        players: prevFormData.players.map((player, index) => {
          if ((index === playerIndex && (!showPlayerForm || (!addPlayer && showPlayerForm))) ||
              (addPlayer && showPlayerForm && index === playerIndex + 1)) {
            return { ...player, idNumber: playerId };
          }
          return player;
        }),
        
      }));
    } else {
      setDuplicateIdError(true);
    }
    setPlayerIdDialogOpen(false);
    setShowLoginScreen(false);
    setPlayerId('');
    setAddPlayer(false);
  } catch (error) {
    console.error('Error submitting form:', error);
  }
};
useEffect(() => {
}, [formData.players]);

const isValidIsraeliID = (id: string): boolean => {
  if (id.length !== 9) return false;
  return Array.from(id, Number).reduce((counter, digit, i) => {
    const step = digit * ((i % 2) + 1);
    return counter + (step > 9 ? step - 9 : step);
  }) % 10 === 0;
};

const handlePlayerIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;

  // הגבלה על מספר תווים ל-9 בלבד
  if (value.length > 9) {
    value = value.slice(0, 9);
  }
  setPlayerId(value);

  // בדיקת קלט עבור תווים שאינם מספרים
  if (!/^\d*$/.test(value)) {
    setErrorMessage('ניתן להכניס מספרים בלבד');
  } 
  // בדיקה אם תעודת הזהות תקינה
  else if (!isValidIsraeliID(value)) {
    setErrorMessage('תעודת זהות לא תקינה');
  } 
  else {
    setErrorMessage('');
  }
};



const handleOpenPlayerIdDialog = () => {
  setShowLoginScreen(false);
  setPlayerIdDialogOpen(true);
};

const handleClosePlayerIdDialog = () => {//סגירת דיאלוג בדיקת תז כשהמשתמש לוחץ ביטול
  if(showPlayerForm){//כבר פתחנו את הטופס שזה אומר שאנחנו לא בשחקן הראשון
    setShowLoginScreen(false);
  }

if(!showPlayerForm){
    setShowLoginScreen(true);
  }
  setPlayerIdDialogOpen(false);
  setPlayerId('');
};

const handleCloseDuplicateIdDialog = () => {
  if (playerIndex !== 0) {
    setShowLoginScreen(false);
    setPlayerIdDialogOpen(false);
    setShowPlayerForm(true);
  
  } else {
    setShowLoginScreen(true);
  }
  setDuplicateIdError(false);
  setPlayerId('');
};

const handleOpenTermsDialog = () => {
  setTermsDialogOpen(true);
};

const handleCloseTermsDialog = () => {
  setTermsDialogOpen(false);
};

const handleCloseSuccessDialog = () => {
  setShowLoginScreen(true);
  setShowPlayerForm(false);
  setOpenSuccessDialog(false);
};

const goToPreviousPlayer = () => {
  setPlayerIndex(playerIndex - 1);
};
const goToNextPlayer = () => {
  setPlayerIndex(playerIndex + 1);
};



//************************************************************************************************
// פונקציה שקולטת את השינוים שהמשתמש מקליד ואוספת אותם לאובייקט שישלח לשרת
//************************************************************************************************

const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value, type } = event.target;
  const finalValue = type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;

  const hasError = validateInput(name, value);
    if (hasError) {return};

  setFormData(prevState => {
    const updatedFormData = { ...prevState };

    if (name.includes('.')) {//עדכון שדה שיש בשם שלו נקודה. (למשל: address.street)
      const [mainField, subField] = name.split('.') as [keyof FormData, string];
      if (mainField in updatedFormData) {
        (updatedFormData[mainField] as any)[subField] = finalValue;
      }
    } else {
      if (name in prevState) {// עדכון שדה רגיל
        (updatedFormData as any)[name] = finalValue; // Update main form fields
      } else {//עדכון שדה של שחקן. השחקן שמעדכנים נבחר לפי playerIndex
        const updatedPlayers = updatedFormData.players.map((player, index) =>
          index === playerIndex ? { ...player, [name]: finalValue } : player
        );
        updatedFormData.players = updatedPlayers;
      }
    }

    if (["selection", "club", "eddEquipment", "monthsToPay"].includes(name)) {//חישוב תשלום לשחקן בודד
      let payment = 0;
    
      const updatedPlayers = updatedFormData.players.map((player, index) => {
        if (index === playerIndex) {
          if (name === "selection") {// אם שנינו את השדה הזה אז זה אומר שהמשתמש רוצה לעבור מחוג לקבוצה או להפך ולכן הכל מתאפס
            return { ...player, club: '',category:'', eddEquipment: false, monthsToPay: numOfPayments, playerPayment: 0 };
          } else {
            payment = calculateTotalPlayerCost({ ...player, [name]: finalValue }, index);
            return { ...player, [name]: finalValue , playerPayment: payment };
          }
        }
        return player;
      });
    
      updatedFormData.players = updatedPlayers;
      setTotalPlayerPayment(payment);
    }
    
    return updatedFormData;
  });
};

const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
  const { name, value } = event.target;
  validateInput(name, value, true);
};

const validateInput = (name: string, value: string, onBlur: boolean = false): boolean => {
  let hasError = false;

  const setError = (message: string) => {setErrors(prevErrors => ({ ...prevErrors, [name]: message }));hasError = true};
  const clearError = () => {setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))};

  if (name.includes("Phone")) {
    if (!/^\d*$/.test(value)) {
      setError('ניתן להקיש ספרות בלבד');
    } else if (value.startsWith('05') && value.length === 10) {
      clearError();
    } else if (onBlur && (!value.startsWith('05') ||  value.length !== 10)) {
      setError('מספר טלפון לא תקין, יש להקליד עשר ספרות בתבנית: 0501234567');
    }
  }
  return hasError;
};

const renderErrorMessage = (fieldName: string) => {
  return errors[fieldName] ? (
    <div className="error-message" style={{ color: 'red' }}>
      {errors[fieldName]}
    </div>
  ) : null;
};


//************************************************************************************************
// פונקציה ששולחת את הטופס לשרת
//************************************************************************************************


const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  // בדיקה אם כל השחקנים אישרו את תנאי השימוש
  const allPlayersApproved = formData.players.every(player => player.parentDeclaration);

  if (!allPlayersApproved) {
    alert('יש לאשר את תנאי השימוש עבור כל השחקנים לפני המשך לתשלום');
    return; // עצירת הפונקציה אם התנאי לא מתקיים
  }
   // בדיקה האם לפחות אחד מהזוגות מלא
   const isFatherInfoFilled = formData.fatherName && formData.fatherPhone;
   const isMotherInfoFilled = formData.motherName && formData.motherPhone;
 
   if (!isFatherInfoFilled && !isMotherInfoFilled) {
     alert("אנא מלאו את פרטי אחד ההורים (שם+טלפון) לפחות");
     return;
   }

  console.log('Form Data before submit:', formData); // בדיקה של formData

  try {
    // שמירת formData ב-Session Storage
    localStorage.setItem('formData', JSON.stringify(formData));
    console.log('Saved Form Data:', localStorage.getItem('formData'));    

    // העברת המשתמש לנתיב /creditcardHok עם formData כ-state
    navigate('/creditcardHok', { state: formData });
  } catch (error) {
    console.error('Error submitting form:', error);
    setDialogMessage('תקלה בשליחת הנתונים');
  } finally {
    setOpenSuccessDialog(true);
    setShowPlayerForm(false);
    setPlayerIndex(0);
    setFormData(initialFormData);
    setTotalPayment(0);
    setTotalPlayerPayment(0);
  }
};






//************************************************************************************************
// פונקציה שפותחת טופס לשחקן שני 
//************************************************************************************************

const handleAddPlayerCheckboxChange = () => {
  console.log(formData.players[playerIndex].selection)
  const player = formData.players[playerIndex];

  // רשימה של השדות הנדרשים
  const requiredFields: (keyof Player)[] = ['playerFirstName', 'idNumber', 'birthDate', 'clothingSize', 'school', 'grade', 'selection', 'club', 'healthType'];

const emptyFields = requiredFields.filter(field => !player[field]);

  // אם יש שדות ריקים, הצג הודעת שגיאה
  if (emptyFields.length > 0) {
    const missingFields = emptyFields.map(field => {
      switch (field) {
        case 'playerFirstName':
          return 'שם השחקן';
        case 'idNumber':
          return 'מספר ת.ז.';
        case 'birthDate':
          return 'תאריך לידה';
        case 'clothingSize':
          return 'מידת בגד';
        case 'school':
          return 'שם הבית ספר';
        case 'grade':
          return 'כיתה';
        case 'selection':
          return 'בחירת חוג/קבוצה';
        case 'club':
          return formData.players[playerIndex].selection === 'clubs' ? 'מקום החוג' : 'קטגוריה';
          case 'category':
            return formData.players[playerIndex].selection === 'teams' ? 'שם הקבוצה' : '';
        case 'healthType':
          return 'סוג ביטוח בריאות';
        default:
          return '';
      }
    }).join(', ');
  
    alert(`לא מילאת את השדות הבאים: ${missingFields}`);
    return;
  }
  
  

  // הפעולות הנדרשות כאשר השדות מולאו תקין
  setAddPlayer(true);
  setPlayerIdDialogOpen(true);
  setChecked(false);
};


const addEmptyPlayer = () => {
  setFormData(prevState => ({
    ...prevState,
    players: [
      ...prevState.players,
      {
        playerFirstName: '',
        playerPhone: '',
        idNumber: '',
        birthDate: '',
        clothingSize: '',
        school: '',
        grade: '',
        selection: '',
        eddEquipment: false,
        monthsToPay:numOfPayments,
        club: '',
        category: '',
        parentDeclaration: false,
        healthType: '',
        playerPayment: 0,
      },
    ],
  }));
   // גלילה למיקום השחקן החדש לאחר הוספתו
   setTimeout(() => {
    if (playerSectionRef.current) {
      playerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);
};

//************************************************************************************************
// פונקציה לחישוב עלות חוג/קבוצה של שחקן 
//************************************************************************************************


const calculateTotalPlayerCost = (player: Player, index: number) => {
  let totalCost = 0;
  const numOfSelectedPlayers = getNumberOfSelectedPlayers(formData.players, index);
  console.log(`Number of players selected as teams: ${numOfSelectedPlayers}`);

  if (player.selection === "teams") { // השחקן נרשם לקבוצה
    totalCost = player.club.includes(appConfig.boysTeamPayment.toString())
      ? appConfig.boysTeamPayment - numOfSelectedPlayers * appConfig.nextPlayerDiscount
      : player.club.includes(appConfig.girlsTeamPayment.toString())
      ? appConfig.girlsTeamPayment - numOfSelectedPlayers * appConfig.nextPlayerDiscount
      : 0;
  } else if (player.selection === "clubs") { // השחקן נרשם לחוג
    if (player.club === "תלמוד תורה, פעם בשבוע") {
      totalCost = appConfig.TalmudToraPayment * player.monthsToPay;
    } else if (player.club === "מועדון בית ספרי המאירי בנות - פעם בשבוע") {
      totalCost = appConfig.MeiriPayment * player.monthsToPay;  
    } else if (player.club === "עתודה ה ומעלה- אולם בן שמן") {
      totalCost = 100 * player.monthsToPay;
    } else {
      totalCost = player.club.includes(appConfig.clubOnceAWeekString)
        ? appConfig.clubOnceAWeekPayment * player.monthsToPay
        : player.club.includes(appConfig.clubTwiceAAWeekString)
        ? appConfig.clubTwiceAAWeekPayment * player.monthsToPay
        : 0;
    }
    // הוספת ציוד לחוג
    if (player.eddEquipment && player.monthsToPay !== 10) {
      totalCost += appConfig.equipmentCost;
    }
  }
  return totalCost;
};


const getNumberOfSelectedPlayers = (players: Player[], index: number): number => {
  return players.slice(0, index + 1).filter(player => player.selection === 'teams').length-1;
};



useEffect(() => {
  // חישוב הסכום של playerPayment לכל השחקנים אחרי חישוב מחדש עם הפונקציה calculateTotalPlayerCost
  const payment = formData.players.reduce((sum, player, index) => {
    // הפעלת הפונקציה calculateTotalPlayerCost לכל שחקן במערך
    const updatedPayment = calculateTotalPlayerCost(player, index);
    return sum + updatedPayment; // חישוב הסכום הכולל
  }, 0);

  // עדכון state של totalPlayerPayment
  setTotalPayment(payment);
}, [formData.players]); // לוודא שפונקציה זו תלויה גם בcalculateTotalPlayerCost אם היא מחושבת מחדש


const removeCurrentPlayer = () => {
  console.log(formData.players)
  setFormData(prevFormData => {
    const updatedPlayers = prevFormData.players.filter((_, index) => index !== playerIndex);
    
    return {
      ...prevFormData,
      players: updatedPlayers,
    };
  });
  console.log(formData.players)
  // עדכון ה-playerIndex בהתאם לשחקנים שנותרו
  if (playerIndex > 0) {
    setPlayerIndex(playerIndex - 1);
  } else if (playerIndex === 0 && formData.players.length > 1) {
    setPlayerIndex(0);
  } else {
    setShowPlayerForm(false); // הסתרת הטופס אם אין שחקנים נוספים
  }
};





//************************************************************************************************
// התחלת הטופס
//************************************************************************************************

return (
  <div className="dashboard">
    {showLoginScreen && (
      <>
      <div className="form-container">
        <div className="section-title-container">
          <h2 className="the-title">ברוכים הבאים למערכת הרשמה אליצור לוד</h2>
          <span className="short-line"></span>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button ref={buttonRef} variant="contained" color="primary" onClick={handleOpenPlayerIdDialog}>
            לחץ כאן כדי להתחיל
          </Button>
          </div>
        </div>
      </>
    )}

    {showPlayerForm && (
      <>
        <form onSubmit={handleSubmit} className="form-container">
        <div className="section-title-container">
          <h2 className="section-title">פרטי הורים</h2>
          <span className="short-line"></span>
        </div>
          <div className="form-section">
            <div className="form-group">
              <label>שם משפחה:</label>
              <input type="text" name="lastName" placeholder="שם משפחה" value={formData.lastName} onChange={handleChange} required/>
            </div>
            <br />
            <div className="form-group">
              <label>שם האב:</label>
              <input type="text" name="fatherName" placeholder="שם האב" value={formData.fatherName} onChange={handleChange}/>
            </div>
            <br />
            <div className="form-group">
              <label>טלפון נייד ליצירת קשר:</label>
              <input type="tel" pattern="[0-9]*" name="fatherPhone" placeholder="טלפון נייד ליצירת קשר" value={formData.fatherPhone} onChange={handleChange} onBlur={handleBlur}/>
              {renderErrorMessage('fatherPhone')}
            </div>
            <br />
            <div className="form-group">
              <label>שם האם:</label>
              <input type="text" name="motherName" placeholder="שם האם" value={formData.motherName} onChange={handleChange}/>
            </div>
            <br />
            <div className="form-group">
              <label>טלפון נייד נוסף:</label>
              <input type="tel" pattern="[0-9]*" name="motherPhone" placeholder="טלפון נייד נוסף" value={formData.motherPhone} onChange={handleChange} onBlur={handleBlur} />
              {renderErrorMessage('motherPhone')}
            </div>
            <br />
            <div className="form-group">
              <label>מייל לקבלות ויצירת קשר:</label>
              <input type="email" name="email" placeholder="מייל לקבלות ויצירת קשר" value={formData.email} onChange={handleChange} required/>
            </div>
            <br />
            <div className="form-group">
              <label>כתובת: רחוב, מספר, עיר</label>
              <input type="text" name="address.street" placeholder="רחוב" value={formData.address.street} onChange={handleChange} required/>
              <input type="text" name="address.number" placeholder="מספר" value={formData.address.number} onChange={handleChange} required/>
              <input type="text" name="address.city" placeholder="עיר" value={formData.address.city} onChange={handleChange} required/>
            </div>
          </div>

          <div className="button-group" ref={playerSectionRef}>
          {playerIndex > 0 && (
            <>
              <button type="button" onClick={goToPreviousPlayer}>לחץ לחזרה לשחקן/ית הקודם</button>
              
            </>
          )}
          {formData.players.length > 1 && (
            <>
              <button type="button" onClick={removeCurrentPlayer} style={{ backgroundColor: 'red', color: 'white' }}>
                מחק שחקן/ית נוכחי/ת
              </button>
              {playerIndex + 1 < formData.players.length && (
                <button type="button" onClick={goToNextPlayer}>
                  לחץ למעבר לשחקן/ית הבא
                </button>
              )}
            </>
          )}
       </div>
          <>
        <div key={playerIndex}>
          <PlayerDetailsForm
            formData={formData}
            playerIndex={playerIndex}
            handleChange={handleChange}
            handleOpenTermsDialog={handleOpenTermsDialog}
            handleOpenPlayerIdDialog={handleOpenPlayerIdDialog}
            handleBlur={handleBlur}
            renderErrorMessage={renderErrorMessage}
          />
          {(playerIndex+1)<appConfig.numOfPlayers&&formData.players.length<appConfig.numOfPlayers  && (
            <>
          <div className="section-title-container">
            <h2 className="section-title">רישום שחקן/ית נוסף (חוג/ליגה)</h2>
            <span className="short-line"></span>
          </div>
          
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="secondPlayer"
                checked={checked}
                onChange={(e) => {
                  if (formData.players[playerIndex].parentDeclaration) {
                    handleAddPlayerCheckboxChange(); // אם parentDeclaration הוא true, הכפתור יפעל כרגיל
                  } else {
                    alert('עליך לאשר את תנאי ההרשמה לפני שתוכל להוסיף שחקן/ית נוסף/ת'); // אם parentDeclaration הוא false, תופיע הודעה
                  }
                }}
              />
              לחץ להוספת שחקן/ית
            </label>
</div>

                  </>
                  )}
        </div>
    </>
          <div className="section-title-container">
            <h2 className="section-title"> תנאים נוספים </h2>
            <span className="short-line"></span>
          </div>
          <div className="form-section">
            <div className="form-group">
              <label>
                <input type="checkbox" name="conditions" checked={formData.conditions} onChange={handleChange} />
                אני מאשר/ת בזאת לאגודה להשתמש בצילומ/ים בו/הם בני/ביתי מופיע/ה בפרסומי האגודה ולא אוכל לבוא בכל טענה או דרישה או תביעה כלפי האגודה בקשר לפרסום הצילום ולשימוש בו בהתאם לכתב הסכמה זה.
              </label>
            </div>
          </div>
          <h2 className="the-title">
              סכום לתשלום: {totalPayment}
             {totalPayment !== 0 && `, החיוב מבוצע ב-${numOfPayments} תשלומים`}
          </h2>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button type="submit">המשך לתשלום</button>
          </div>
        </form>
      </>
    )}

{/* ************************************************************************************************
    דיאלוג הכנסת תז שחקן
************************************************************************************************ */}

<Dialog open={playerIdDialogOpen} onClose={handleClosePlayerIdDialog}>
      <DialogTitle style={{ textAlign: 'center' }}>הכנס מספר תעודת זהות</DialogTitle>
      <DialogContent style={{ textAlign: 'right' }}>
        <div className="form-group">
        <body className="right-align">יש להכניס מספר תעודת זהות שחקן/ית</body>
          {(!showPlayerForm) && (
            <label>המערכת תפתח את הטופס רק אם המספר לא קיים במערכת</label>
          )}
         
           <input
          type="text"
          name="playerId"
          placeholder="תעודת זהות"
          value={playerId}
          onChange={handlePlayerIdChange}
        />
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={handleClosePlayerIdDialog} color="secondary">
          ביטול
        </Button>
        <Button 
  disabled={!!errorMessage || !playerId || (playerId.length !== 9 && !isValidIsraeliID(playerId))} 
  onClick={handlePlayerIdSubmit} 
  color="primary"
>
  המשך
</Button>
      </DialogActions>
    </Dialog>

 {/* ************************************************************************************************
    דיאלוג תז שחקן קיים במערכת
************************************************************************************************ */}

    {duplicateIdError && (
      <Dialog open={duplicateIdError} onClose={handleCloseDuplicateIdDialog}>
        <DialogTitle style={{ textAlign: 'right' }}>מספר תעודת זהות כבר קיים במערכת</DialogTitle>
        <DialogContent style={{ textAlign: 'right' }}>
          <p>המערכת מצאה כי מספר תעודת הזהות שהזנת כבר קיים במערכת. אנא בדוק שנית את מספר תעודת הזהות שהזנת ונסה שוב</p>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleCloseDuplicateIdDialog} color="secondary">
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    )}

    {/* ************************************************************************************************
    דיאלוג שליחת טופס נכשלה/הצליחה
    ************************************************************************************************ */}

    <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography variant="h6" component="div">
          הודעה
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Typography variant="body1">
          {dialogMessage}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={handleCloseSuccessDialog} color="primary">
          סגור
        </Button>
      </DialogActions>
    </Dialog>
    
    {/* ************************************************************************************************
    דיאלוג הצהרת הורים: חוג או קבוצה
    ************************************************************************************************ */}
          {formData.players[playerIndex]?.selection ? (
  <Dialog open={termsDialogOpen} onClose={handleCloseTermsDialog}>
    <DialogTitle className="dialog-title">
      {formData.players[playerIndex]?.selection === 'clubs'
        ? 'הצהרת הורים - חוגים'
        : formData.players[playerIndex]?.selection === 'teams'
        ? 'הצהרת הורים - קבוצה'
        : ''}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <div className="form-section">
          <div className="form-group right-align">
            {formData.players[playerIndex]?.selection === 'clubs' ? (
              <p>{appConfig.terms}</p>
            ) : formData.players[playerIndex]?.selection === 'teams' ? (
              <p>{appConfig.terms2}</p>
            ) : null}
          </div>
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center' }}>
      <Button onClick={handleCloseTermsDialog} color="primary">
        סגור
      </Button>
    </DialogActions>
  </Dialog>
) : (
  <Dialog open={termsDialogOpen} onClose={handleCloseTermsDialog}>
    <DialogTitle className="dialog-title">הודעה</DialogTitle>
    <DialogContent>
      <DialogContentText>
        נא לבחור חוגים/קבוצה כדי לנוכל לספק את תנאי הרישום
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center' }}>
      <Button onClick={handleCloseTermsDialog} color="primary">
        סגור
      </Button>
    </DialogActions>
  </Dialog>
)}




  </div>
);
};
export default Dashboard;
