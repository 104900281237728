import React, { useState, useRef } from 'react';
import './Dashboard.css';
import './PlayerDetailsForm.css';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import appConfig from '../../utils/appConfig';
import { FormHelperText } from '@material-ui/core';



interface Player {
  playerFirstName: string;
  idNumber: string;
  playerPhone: string;
  birthDate: string;
  clothingSize: string;
  school: string;
  grade: string;
  selection: string;
  eddEquipment: boolean;
  monthsToPay:number,
  club: string;
  category: string;
  healthType: string;
  parentDeclaration: boolean;
}

interface FormData {
  players: Player[];
}

interface PlayerDetailsFormProps {
  formData: FormData;
  playerIndex: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleOpenTermsDialog: () => void;
  handleOpenPlayerIdDialog?: () => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void; 
  renderErrorMessage: (fieldName: string) => React.ReactNode; 
}

const PlayerDetailsForm: React.FC<PlayerDetailsFormProps> = ({ formData, playerIndex, handleChange, handleOpenTermsDialog, handleOpenPlayerIdDialog ,handleBlur,renderErrorMessage }) => {
  
  
 
  const monthsHebrew = [
    "ספטמבר", "אוקטובר", "נובמבר", "דצמבר", "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני"
  ];

  const getMonthsFromCurrentToSeptember = () => {
    
    let currentMonthIndex = new Date().getMonth(); // מקבלים את האינדקס של החודש הנוכחי (0-11)
    console.log(currentMonthIndex)
    //let currentMonthIndex=10;
    currentMonthIndex>7? currentMonthIndex=currentMonthIndex-8:currentMonthIndex=currentMonthIndex+4;
  
    let months = [];
      months = monthsHebrew.slice(0, currentMonthIndex + 1);
    return months;
  };

  const months= getMonthsFromCurrentToSeptember();
  console.log(months)
  console.log(monthsHebrew)

  

  return (
    <>
      <div className="section-title-container">
        <h2 className="section-title">פרטי שחקן/ית {playerIndex+1} (חוג/ליגה)</h2>
        <span className="short-line"></span>
      </div>
      <div className="form-section">
        <div className="form-group">
          <label>שם פרטי שחקן/ית:</label>
          <input 
            type="text" 
            name="playerFirstName"
            placeholder="שם פרטי שחקן/ית" 
            value={formData.players[playerIndex]?.playerFirstName}
            onChange={handleChange} 
            required
          />
        </div>
        <br />
        <div className="form-group">
        <div className="label-container">
          <label>תעודת זהות שחקן/ית:</label>
          <button type="button" className="toggle-button" onClick={handleOpenPlayerIdDialog}>
              ערוך מספר תעודת זהות
            </button>
        </div>
      <input 
        type="text" 
        name="idNumber" 
        placeholder="תעודת זהות" 
        value={formData.players[playerIndex]?.idNumber}    
        onChange={handleChange} 
        readOnly // שדה ניתן לעריכה או קריאה בלבד בהתאם למצב
        required
      />
    </div>
        <br />
        <div className="form-group">
          <label>טלפון נייד שחקן/ית:</label>
          <input 
            type="tel" 
            pattern="[0-9]*" 
            name="playerPhone" 
            placeholder="טלפון נייד שחקן/ית" 
            value={formData.players[playerIndex]?.playerPhone} 
            onChange={handleChange} 
            onBlur={handleBlur}
          />
          {renderErrorMessage('playerPhone')}
        </div>
        <br />
        <div className="form-group">
          <label>תאריך לידה:</label>
          <input 
            type="date" 
            name="birthDate" 
            value={formData.players[playerIndex]?.birthDate} 
            onChange={handleChange} 
            required
          />
        </div>
        <br />
        <div className="form-group">
          <label>מידת ביגוד:</label>
          <select 
            name="clothingSize" 
            value={formData.players[playerIndex]?.clothingSize || ''} 
            onChange={handleChange}
            required
          >
           <option value="" disabled>בחר מידה</option>
    {['4', '6', '8', '10', '12', '14', '16', '18', '20', 'S', 'M', 'L', 'XL'].map(size => (
      <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>
        <br />
        <div className="form-group">
          <label>מוסד חינוכי בו לומד הילד/ה:</label>
          <select 
            name="school" 
            value={formData.players[playerIndex]?.school|| ''} 
            onChange={handleChange}
            required
          >
            <option value="" disabled>מוסד חינוכי בו לומד הילד/ה</option>
            {['המאירי בנים (נעם רז)', 'המאירי בנות', 'נעם נהורא', 'רמב"ם', 'תלמוד תורה', 'נעם נריה', 'ישיבה תיכונית', 'אולפנת צביה'].map(school => (
              <option key={school} value={school}>{school}</option>
            ))}
          </select>
        </div>
        <br />
        <div className="form-group">
          <label>כיתה:</label>
          <select 
            name="grade" 
            value={formData.players[playerIndex]?.grade|| ''} 
            onChange={handleChange}
            required
          >
            <option value="" disabled>כיתה</option>
            {['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'יא', 'יב'].map(grade => (
              <option key={grade} value={grade}>{grade}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="section-title-container">
        <h2 className="section-title">בחירת חוג / קבוצה</h2>
        <span className="short-line"></span>
      </div>
      <div className="form-section">
      <div className="form-group">
  <label>בחירת מסגרת:</label>
  <div className="selection-options">
    <label>
      <input 
        type="radio" 
        name={"selection"} 
        value="clubs" 
        onChange={handleChange} 
        required
        checked={formData.players[playerIndex]?.selection === 'clubs'}
      /> חוגים
    </label>
    <label>
      <input 
        type="radio" 
        name={"selection"} 
        value="teams" 
        onChange={handleChange} 
        required
        checked={formData.players[playerIndex]?.selection === 'teams'}
      /> קבוצות ליגה
    </label>
  </div>
</div>


      </div>
      
      {formData.players[playerIndex]?.selection === 'clubs' && (
        <div className="form-section">
          <div className="form-group">
            <DialogContentText>
               עלות החוג הינה {appConfig.clubTwiceAAWeekPayment} ש"ח לחודש<br />  בחוגי בנות תינתן אופצייה לפעם בשבוע בעלות של {appConfig.clubOnceAWeekPayment} שקלים.<br />
            </DialogContentText>
            <div className="form-group">
            <label>רכישת ציוד:</label>
            <br />
            <label>עלות הציוד: 300 ש"ח. לנרשמים בספטמבר- הציוד ללא עלות!</label>
            <br />
              <label>
                <input 
                  type="checkbox" 
                  name="eddEquipment" 
                  checked={formData.players[playerIndex]?.eddEquipment||false} 
                  onChange={handleChange} 
                />
                מעוניין להוסיף ציוד 
              </label>
              <br />
              <DialogContentText>
                חובה על השחקן/ית להגיע עם ציוד לחוג.
              </DialogContentText>
            </div>
            <label>מקום החוג:</label>
            <br />
            <select 
              name="club" 
              value={formData.players[playerIndex]?.club} 
              onChange={handleChange}
              required
            >
              <option value="" disabled>מקום החוג</option>
             {[
    'נריה (אלישיב) פעם בשבוע (בנות בלבד)', 
    'נריה (אלישיב), פעמיים בשבוע',
    'המאירי, פעם בשבוע (בנות בלבד)', 
    'המאירי, פעמיים בשבוע',
    'רמבם, בזמן הצהרון פעם בשבוע (בנות בלבד)', 
    'רמבם, בזמן הצהרון פעם בשבוע (בנים בלבד)', 
    'רמבם, אחר הצהריים פעמיים בשבוע',
    'רמבם, בזמן הצהרון -פעמיים בשבוע - בנים',
    'תלמוד תורה, פעם בשבוע',
    'מועדון בית ספרי המאירי בנות - פעם בשבוע',
    'עתודה ה ומעלה- אולם בן שמן' // New option
  ]
              .map(club => (
                <option key={club} value={club}>{club}</option>
              ))}
            </select>
            <br />
            {/* אפשרות לתשלום של חודש קודם. דוגמא: אדם נרשם באוקטובר אבל רוצה לשלם מספטמבר */}
                {/* {formData.players[playerIndex]?.club && months.length>1&& (
                  <>
                    <label>חודש התחלה (ברירת מחדל - ספטמבר):</label>
                    <br />
                    <select 
                      name="monthsToPay" 
                      value={formData.players[playerIndex]?.monthsToPay || ''} 
                      onChange={handleChange}
                    >
                      <option value="" disabled>חודש כניסה לחוג</option>
                      {months.map((month, index) => (
                        <option key={index} value={monthsHebrew.length - index}>{month}</option>
                      ))}
                    </select>
                  </>
                )} */}
          </div>
        </div>
      )}
      
      {formData.players[playerIndex]?.selection === 'teams' && (
        <div className="form-section">
          <div className="form-group">
            <br />
              <DialogContentText>
               הנחה לרישום מספר ילדים מאותה משפחה: לכל ילד נוסף התשלום מופחת ב-500 ש"ח מהאח הקודם
              </DialogContentText>
            <select 
              name="club" 
              value={formData.players[playerIndex]?.club} 
              onChange={handleChange}
              required
            >
              <option value="" disabled>בחר קטגוריה</option>
              {[`בנים (${appConfig.boysTeamPayment} ש"ח)`, `בנות (${appConfig.girlsTeamPayment} ש"ח)`].map(club => (
                <option key={club} value={club}>{club}</option>
              ))}
            </select>
            <br />
            <br />
            <select 
              name="category" 
              value={formData.players[playerIndex]?.category} 
              onChange={handleChange}
              required
            >
              <option value="" disabled>בחר קבוצה</option>
              {
              // ['קט סל ב המאירי',' קט סל ב אלישיב',' קט סל א המאירי' ,' קט סל א אלישיב','ילדים ב המאירי','ילדים ב אלישיב',' ילדים א לאומית',' ילדים א מחוזית' ,' נערים ב',' נערים א' ,' נוער',  ' ילדות א ', ' נערות ב ']
              ['קט סל ב (כיתה ה)','קט סל א (כיתה ו)',' ילדים ב (כיתה ז)' ,' ילדים א (כיתה ח)','נערים ב (כיתה ט)','נערים א (כיתה י)',' נוער (כיתות יא-יב)',' נערות ב (כיתה ט)' ,' ילדות ב (כיתה ז-ח)']
              .map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
        </div>
      )}
      
      <div className="section-title-container">
        <h2 className="section-title">הצהרת בריאות</h2>
        <span className="short-line"></span>
      </div>
      <div className="form-section">
        <div className="form-group">
          <p>
            הנני מצהיר בזה:
            <br />
            א. המצב הבריאותי של בני/בתי תקין/ה ולא ידוע לי על מגבלות בריאות המונעות מבני/בתי להשתתף בכל הפעילויות הנדרשות במסגרת בית-הספר לכדורסל.
            <br />
            ב. אני מתחייב/ת להודיע למאמן הקבוצה של בני/בתי על שינוי ו/או הגבלה שיחולו במצב בריאותו/ה.
            <br />
            ג. אני מאשר/ת מתן ידע למאמן המופקד/ים על הנושאים הכרוכים במאמץ גופני, בכל הקשור למגבלתו ולהשתתפותו/ה בפעילות הגופנית.
          </p>
        </div>
        <div className="form-group">
          <label>קופת חולים:</label>
          <div className="player.health-options">
            <label>
              <input 
                type="radio" 
                name="healthType" 
                value="כללית" 
                onChange={handleChange} 
                required
              /> כללית
            </label>
            <label>
              <input 
                type="radio" 
                name="healthType" 
                value="לאומית" 
                onChange={handleChange} 
                required
              /> לאומית
            </label>
            <label>
              <input 
                type="radio" 
                name="healthType" 
                value="מכבי" 
                onChange={handleChange} 
                required
              /> מכבי
            </label>
            <label>
              <input 
                type="radio" 
                name="healthType" 
                value="מאוחדת" 
                onChange={handleChange} 
                required
              /> מאוחדת
            </label>
          </div>
        </div>
      </div>

      <div className="section-title-container">
        <h2 className="section-title">הצהרת הורים {formData.players[playerIndex]?.selection === 'clubs' ? "- חוג" : (formData.players[playerIndex]?.selection === 'teams' ? "- קבוצה" : "")}:</h2>
        <span className="short-line"></span>
      </div>
      <div className="form-section">
      <div className="form-group">
  <label style={{ display: 'flex', alignItems: 'center' }}>
    <input 
      type="checkbox" 
      name="parentDeclaration" 
      checked={formData.players[playerIndex]?.parentDeclaration} 
      onChange={handleChange} 
      required
      style={{ marginRight: '5px' }} // מרווח קטן בצד ימין של ה-checkbox
    />
    <span>
      אני מאשר שקראתי והבנתי את
    </span>
  </label>
  <span 
    className="move-up" 
    style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer', marginLeft: '5px' }} 
    onClick={handleOpenTermsDialog}
  >
    תנאי הרישום
  </span>
</div>
      </div>
    </>
  );
}

export default PlayerDetailsForm;
